import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
const Header = ({ siteTitle, menuLinks }) => (
  <header>
    <div>
      <div>
        {/* <h1 style={{ margin: 0, flex: 1 }}>
          <Link
            className="header-link-home" 
            to="/"
            style={{
              textDecoration: "none",
            }}
          >
            {siteTitle}
          </Link>
        </h1> */}
        <div>
          <nav>
            <ul style={{ display: "flex", flex: 1 }}>
              {menuLinks.map(link => (
                <li
                  className="header-menu"
                  key={link.name}
                  style={{
                    listStyleType: `none`,
                    paddingRight: `1rem`,
                  }}
                >
                  <Link to={link.link}>
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </header>
)
Header.propTypes = {
  siteTitle: PropTypes.string,
}
Header.defaultProps = {
  siteTitle: ``,
}
export default Header
import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import Header from "./header"

const Layout = ({ location, title, children }) => {
  const data = useStaticQuery(graphql`
    query SiteMenuQuery {
      site {
        siteMetadata {
          title
          menuLinks {
            name
            link
          }
        }
      }
    }
  `)

  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  const isGlobalPage = location.pathname === "/about"
  let header

  if (isRootPath || isGlobalPage) {
    header = (
      <div>
        <h1 className="main-heading">
          <Link to="/">{title}</Link>
        </h1>
        <Header
          menuLinks={data.site.siteMetadata.menuLinks}
          siteTitle={data.site.siteMetadata.title}
        />
      </div>
    )
  } else {
    header = (
      <div>
        <Link className="header-link-home" to="/">
          {title}
        </Link>
        <Header
          menuLinks={data.site.siteMetadata.menuLinks}
          siteTitle={data.site.siteMetadata.title}
        />
      </div>
    )
  }

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <header className="global-header">{header}</header>
      <main>{children}</main>
      <footer>
        © {new Date().getFullYear()}, Built with
        {` `}
        <OutboundLink href="https://www.gatsbyjs.com">Gatsby</OutboundLink>,
        hosted on{" "}
        <OutboundLink href="https://cloudflare.com">Cloudflare ❤️</OutboundLink>
      </footer>
    </div>
  )
}

export default Layout
